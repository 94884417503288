import React, { useContext, useEffect } from "react";

import {
  Box,
  Typography,
  TextField,
  Checkbox,
  LinearProgress,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  FormControl,
  InputLabel,
  Select,
  SelectChangeEvent,
  MenuItem,
} from "@mui/material";

import DashboardContext from "../../../../Contexts/dashboardContext.tsx";
import { ModalList, client } from "../../../../Config/styling.tsx";

export default function TeamItem(props: {
  teamId: string;
  setTeamId: React.Dispatch<string>;
  teamOptions: any;
  setTeamOptions: React.Dispatch<any>;
  isEdit: boolean;
}) {
  const { orgCollectionsActive } = useContext(DashboardContext);

  /* update local state when props update */
  const [teamId, setTeamId] = React.useState<string>(props.teamId);
  const [teamOptions, setTeamOptions] = React.useState<any>(props.teamOptions);

  useEffect(() => {
    setTeamId(props.teamId);
  }, [props.teamId]);

  useEffect(() => {
    setTeamOptions(props.teamOptions);
  }, [props.teamOptions]);

  const handleToggle = (value: any) => () => {
    const newOptions = JSON.parse(JSON.stringify(props.teamOptions));
    const currentIndex = newOptions.collections.indexOf(value);

    if (currentIndex === -1) {
      newOptions.collections.push(value);
    } else {
      newOptions.collections.splice(currentIndex, 1);
    }
    props.setTeamOptions(newOptions);
  };

  return (
    <Box>
      {props.isEdit ? (
        <Box sx={{ minWidth: 120 }}>
          <TextField
            id="editTeam"
            label="Update Team display name"
            value={props.teamOptions.display_name || ""}
            type="search"
            variant="standard"
            multiline
            fullWidth
            margin="dense"
            onChange={(event: any) => {
              let tempTeamInfo = JSON.parse(JSON.stringify(teamOptions));

              tempTeamInfo.display_name = event.target.value as string;
              props.setTeamOptions(tempTeamInfo);
            }}
          />
          <br />
          <br />

          <FormControl fullWidth>
            <InputLabel id="status-select-label" color="secondary">
              status
            </InputLabel>
            <Select
              MenuProps={client.Select_MenuProps}
              color="secondary"
              labelId="status-select-label"
              id="status-select"
              value={teamOptions.status || "active"}
              label="Status"
              onChange={(event: SelectChangeEvent) => {
                let tempMemberInfo = JSON.parse(JSON.stringify(teamOptions));

                tempMemberInfo.status = event.target.value as string;
                props.setTeamOptions(tempMemberInfo);
              }}
            >
              <MenuItem value={"active"}>Active</MenuItem>
              <MenuItem value={"inactive"}>Inactive</MenuItem>
            </Select>
          </FormControl>
        </Box>
      ) : (
        <TextField
          color="secondary"
          id="newTeam"
          label="Enter Team Name"
          value={props.teamId}
          type="search"
          variant="standard"
          multiline
          fullWidth
          margin="dense"
          onChange={(event: any) => {
            props.setTeamId(event.target.value);
          }}
        />
      )}
      <br />
      <Typography
        id="new-member-status"
        variant="h6"
        color="secondary"
        gutterBottom
        sx={{ mt: 1 }}
      >
        {props.isEdit ? "Update" : "Select"} collection&#40;s&#41; for team
        <b> {teamId}</b>:
      </Typography>

      {orgCollectionsActive ? (
        <ModalList>
          {orgCollectionsActive.map((collection: any) => (
            <li key={`section-${collection.id}`}>
              <ul>
                <ListItem
                  key={`item-${collection.id}`}
                  sx={{ padding: 0 }}
                  dense
                >
                  <ListItemButton
                    role={undefined}
                    onClick={handleToggle(collection.id)}
                    dense
                  >
                    <ListItemIcon>
                      <Checkbox
                        color="secondary"
                        onClick={handleToggle(collection.id)}
                        id="collection_view"
                        edge="start"
                        checked={
                          teamOptions.collections.indexOf(collection.id) !== -1
                        }
                        tabIndex={-1}
                        disableRipple
                        inputProps={{
                          "aria-labelledby": collection.id,
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText primary={collection.id} />
                  </ListItemButton>
                </ListItem>
              </ul>
            </li>
          ))}
        </ModalList>
      ) : (
        <LinearProgress color="secondary" />
      )}
    </Box>
  );
}
