import React, { useContext } from "react";

import {
  Modal,
  Box,
  Alert,
  Typography,
  Stack,
  Button,
  LinearProgress,
  TextField,
} from "@mui/material";

import { ModalStyle2 } from "../../../Components/modalStyle.tsx";
import { GetOrgData, RemoveOrgItem } from "../../../firebase/getUserData.tsx";
import DashboardContext from "../../../Contexts/dashboardContext.tsx";
import UserContext from "../../../Contexts/userContext.tsx";

export default function RemoveItemModal(props: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  itemType: string;
  item: any;
  token: string;
}) {
  const { setOrgMembers, setOrgCollections, setOrgTeams } =
    useContext(DashboardContext);
  const { logout } = useContext(UserContext);
  const [confirmName, setConfirmName] = React.useState<string>("");

  /* modal controls */
  const [modalMessage, setModalMessage] = React.useState<any>(null);
  const handleSubmit = () => {
    setModalMessage(
      <Box sx={{ width: "100%" }}>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          {/* {props.info.message} */}
          Loading...
        </Typography>
        <br />
        <LinearProgress color="secondary" />
        <br />
      </Box>
    );
    RemoveOrgItem(props.token, props.itemType, props.item.id, logout).then(
      (response: any) => {
        /* happy path */
        if (response.result === "success") {
          setModalMessage(
            <Box sx={{ width: "100%" }}>
              <Alert severity={"success"}>{"SUCCESS"}</Alert>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                {/* {props.info.message} */}
                {response.message}
              </Typography>
            </Box>
          );

          /* update the appropriate context so that changes are reflected */
          if (props.itemType === "member") {
            GetOrgData(props.token, ["members", "teams"], logout).then(
              (result: any) => {
                setOrgMembers(result.members);
                setOrgTeams(result.teams);
              }
            );
          } else if (props.itemType === "team") {
            GetOrgData(props.token, ["teams"], logout).then((result: any) => {
              setOrgTeams(result.teams);
            });
          } else if (props.itemType === "collection") {
            GetOrgData(props.token, ["collections"], logout).then(
              (result: any) => {
                setOrgCollections(result.collections);
              }
            );
          }

          setTimeout(() => {
            props.setOpen(false);
          }, 7000);

          /* unhappy path */
        } else {
          setModalMessage(
            <Box sx={{ width: "100%" }}>
              <Alert severity={"error"}>{"ERROR"}</Alert>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                {/* {props.info.message} */}
                {response.message}
              </Typography>
              <Button
                variant="outlined"
                fullWidth
                sx={{ m: 1 }}
                onClick={() => {
                  props.setOpen(false);
                }}
              >
                OKAY
              </Button>
            </Box>
          );
        }
      }
    );
  };

  return (
    <Modal
      open={props.open}
      onClose={() => {
        props.setOpen(false);
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={ModalStyle2}>
        {!modalMessage ? (
          <Box>
            <Alert severity={"warning"}>
              {"This is a destructive action and cannot be undone"}
            </Alert>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              {/* {props.info.message} */}
              Are you sure you want to remove {props.itemType}{" "}
              <b>{props.item.id}</b>?
              {props.item.display_name && (
                <>
                  {" (display name "}
                  <b>{props.item.display_name}</b>
                  {")"}
                </>
              )}
            </Typography>
            <br />
            {/* force confirmation */}
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              {/* {props.info.message} */}
              To confirm, enter the name of the {props.itemType} to be deleted:
            </Typography>
            <TextField
              color="secondary"
              id="confirmDelete"
              label="Confirm"
              value={confirmName}
              type="search"
              variant="standard"
              multiline
              style={{
                margin: 10,
                width: "100%",
                background: "#FFFFFF",
              }}
              onChange={(event: any) => {
                setConfirmName(event.target.value);
              }}
            />
            <br />

            <Stack direction="row" sx={{ bottom: 0 }}>
              <Button
                disabled={confirmName !== props.item.id}
                color="secondary"
                variant="contained"
                onClick={handleSubmit}
                fullWidth
                sx={{ m: 1 }}
              >
                SUBMIT
              </Button>
              <Button
                color="secondary"
                variant="outlined"
                fullWidth
                sx={{ m: 1 }}
                onClick={() => {
                  props.setOpen(false);
                }}
              >
                CANCEl
              </Button>
            </Stack>
          </Box>
        ) : (
          modalMessage
        )}
      </Box>
    </Modal>
  );
}
