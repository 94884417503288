import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";

import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";

import Tooltip from "@mui/material/Tooltip";
import Skeleton from "@mui/material/Skeleton";

import UserContext from "../../../Contexts/userContext.tsx";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import {
  Box,
  FormControlLabel,
  FormGroup,
  Switch,
  Typography,
} from "@mui/material";

const defaultMember = {
  type: "member",
  status: "active",
  collection_access: {
    view: [],
    edit: [],
  },
  team_access: {
    view: [],
    edit_membership: [],
    edit_seq_assigments: [],
  },
};

const rowHeaders = [
  "User",
  "Account Type",
  "Account Status",
  "Member Since",
  "Edit",
];

export default function MemberTable(props: {
  members: any;
  setAddOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setEditOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setRemoveOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setUser: React.Dispatch<React.SetStateAction<any>>;
  setItemType: React.Dispatch<React.SetStateAction<string>>;
}) {
  const { user } = React.useContext(UserContext);

  /* toggle controls */
  const [memberList, setMemberList] = React.useState<any>(
    props.members.filter((member: any) => member.status === "active")
  );
  const [hideInactive, setHideInactive] = React.useState<boolean>(true);
  const showDisabled = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      let filteredMembers = props.members.filter(
        (member: any) => member.status === "active"
      );
      setMemberList(filteredMembers);
    } else {
      setMemberList(props.members);
    }
    setHideInactive(!hideInactive);
  };

  return (
    <Paper
      elevation={4}
      sx={{
        p: 2,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box p={2}>
        <Typography fontWeight="bold" color="primary.dark" variant="h5">
          Account Members
        </Typography>
        <FormGroup sx={{ mt: -4 }}>
          <FormControlLabel
            control={
              <Switch
                color="secondary"
                checked={hideInactive}
                onChange={showDisabled}
                name="inactive toggle"
              />
            }
            label={hideInactive ? "Show Inactive" : "Hide Inactive"}
            labelPlacement="start"
          />
        </FormGroup>
      </Box>

      <Stack
        sx={{
          maxHeight: "300px",
          overflow: "scroll",
        }}
      >
        <Table stickyHeader size="small" style={{ width: "100%" }}>
          <TableHead>
            <TableRow>
              {rowHeaders.map((rowHeader: string) => (
                <TableCell
                  key={rowHeader}
                  align={rowHeader === "Edit" ? "center" : "left"}
                  sx={{ color: "secondary.dark" }}
                >
                  <b>{rowHeader}</b>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {memberList.length > 0 ? (
            <TableBody>
              {memberList.map((row: any) => (
                <TableRow key={row.email + row.type}>
                  <TableCell>{row.email}</TableCell>
                  <TableCell>
                    <Chip
                      label={row.type || "user"}
                      color="secondary"
                      variant={row.type === "admin" ? "filled" : "outlined"}
                      sx={{
                        fontWeight: "bold",
                        color: row.type === "admin" ? "white" : "primary.dark",
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <Chip
                      label={row.status || "N/A"}
                      sx={{
                        fontWeight: "bold",
                        color:
                          row.status === "active"
                            ? "secondary.main"
                            : "divider",
                      }}
                      variant="outlined"
                    />
                  </TableCell>
                  <TableCell>
                    {row.date_created
                      ? new Date(
                          row.date_created._seconds * 1000
                        ).toLocaleDateString("en-US")
                      : "unknown"}
                  </TableCell>
                  <TableCell align="right" width={120}>
                    <Tooltip title={"Edit Access"} placement="left">
                      <IconButton
                        aria-label="deleteUser"
                        color="secondary"
                        onClick={() => {
                          props.setEditOpen(true);
                          props.setItemType("member");
                          props.setUser({
                            id: row.email,
                            type: row.type || defaultMember.type,
                            status: row.status || defaultMember.status,
                            sequence_list: row.sequence_list || [],
                            collection_access:
                              row.collection_access ||
                              defaultMember.collection_access,
                            team_access:
                              row.team_access || defaultMember.team_access,
                          });
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>

                    <Tooltip title={"Remove User"} placement="right">
                      <span>
                        <IconButton
                          disabled={user.email === row.email}
                          aria-label="deleteUser"
                          color="secondary"
                          onClick={() => {
                            props.setRemoveOpen(true);
                            props.setItemType("member");
                            props.setUser({
                              id: row.email,
                              display_name: row.display_name || "",
                            });
                          }}
                        >
                          <RemoveCircleOutlineIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          ) : (
            <TableBody>
              <TableRow>
                {/* column for each header */}
                {rowHeaders.map((c, ic) => (
                  <TableCell key={ic}>
                    {/* n = 10 rows */}
                    {[...Array(10)].map((r, ir) => (
                      <Skeleton
                        key={"column:" + ic + "row:" + ir}
                        variant="text"
                        sx={{ width: "100%", fontSize: "1rem" }}
                      />
                    ))}
                  </TableCell>
                ))}
              </TableRow>
            </TableBody>
          )}
        </Table>
      </Stack>
      {/* <Link color="primary" href="#" onClick={preventDefault} sx={{ mt: 3 }}>
        See more activity
      </Link> */}
      <br />
      <Button
        variant="outlined"
        size="small"
        color="secondary"
        fullWidth
        onClick={() => {
          props.setAddOpen(true);
          props.setItemType("member");
          props.setUser(defaultMember);
        }}
      >
        Add Member
      </Button>
    </Paper>
  );
}
