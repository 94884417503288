import React from "react";

import {
  List,
  Grid,
  Tooltip,
  IconButton,
  Avatar,
  Stack,
  ListItem,
  Collapse,
} from "@mui/material";

import StepCard from "./stepCard.tsx";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { builder_step } from "./types";

export default function StepList(props: {
  stepArray: builder_step[];
  setStepArray: React.Dispatch<React.SetStateAction<builder_step[]>>;
  selectedStepIndex: number;
  setSelectedStepIndex: React.Dispatch<React.SetStateAction<number>>;
}) {
  const addStep = (index: number) => {
    let tempStepArray = [...props.stepArray];

    /* set fadeIn & fadeOut to false for all existing steps and swap out the step to be removed with */
    tempStepArray.forEach((step: builder_step) => {
      delete step.fadeIn;
      delete step.fadeOut;
    });

    /* insert a step at the specified position */
    tempStepArray.splice(index + 1, 0, {
      fadeIn: true,
    } as builder_step);

    props.setStepArray(tempStepArray);
  };

  const removeStep = (index: number) => {
    let tempStepArray = [...props.stepArray];

    /* set fadeIn & fadeOut to false for all existing steps and swap out the step to be removed with */
    tempStepArray.forEach((step: builder_step) => {
      delete step.fadeIn;
      delete step.fadeOut;
    });

    tempStepArray[index].fadeOut = true;
    props.setStepArray(tempStepArray);

    setTimeout(() => {
      const newTempStepArray = [...tempStepArray];
      newTempStepArray.splice(index, 1);

      props.setStepArray(newTempStepArray);
    }, 500);
  };

  return (
    <List sx={{ height: "95%", mb: 2, overflow: "scroll" }}>
      <Grid container alignItems="center">
        <Grid item xs={12}>
          <Tooltip title={"Add a step"} placement="right">
            <IconButton
              aria-label="add a step"
              onClick={() => {
                addStep(-1);
              }}
            >
              <AddCircleIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
      {props.stepArray.map((value, index) => {
        const labelId = `checkbox-list-label-${
          JSON.stringify(value) + index.toString()
        }`;

        return (
          <SmoothAppear
            fadeIn={value.fadeIn}
            fadeOut={value.fadeOut}
            key={labelId}
          >
            <Grid container alignItems="center">
              <Grid item xs={1} alignItems="center">
                <Avatar
                  sx={{
                    margin: "auto",
                    backgroundColor: "secondary.dark",
                  }}
                >
                  {index + 1}
                </Avatar>
              </Grid>

              <Grid item xs={10}>
                <Stack width={"100%"}>
                  <ListItem key={labelId}>
                    <StepCard
                      index={index}
                      step={props.stepArray[index]}
                      selectedStepIndex={props.selectedStepIndex}
                      setSelectedStepIndex={props.setSelectedStepIndex}
                    ></StepCard>
                  </ListItem>
                </Stack>
              </Grid>
              <Grid item xs={1}>
                <Tooltip title={"Remove step"} placement="bottom">
                  <IconButton
                    aria-label="remove step"
                    onClick={() => {
                      removeStep(index);
                    }}
                  >
                    <RemoveCircleIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
            <Tooltip title={"Add a step"} placement="right">
              <IconButton
                aria-label="add a step"
                onClick={() => {
                  addStep(index);
                }}
              >
                <AddCircleIcon />
              </IconButton>
            </Tooltip>
          </SmoothAppear>
        );
      })}
    </List>
  );
}

function SmoothAppear({ fadeIn, fadeOut, children }) {
  const [showStep, setShowStep] = React.useState<boolean>(true);

  if (fadeOut) {
    setTimeout(() => {
      setShowStep(false);
    }, 10);
  }

  return (
    <Collapse in={showStep} appear={fadeIn}>
      {children}
    </Collapse>
  );
}
