import * as React from "react";

/* icon imports */
import DashboardIcon from "@mui/icons-material/Dashboard";
import BarChartIcon from "@mui/icons-material/BarChart";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import LockPersonIcon from "@mui/icons-material/LockPerson";
import GroupsIcon from "@mui/icons-material/Groups";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";

import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";

import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import { useNavigate } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import { Tab, Tabs, Typography } from "@mui/material";

import UserContext from "../../Contexts/userContext.tsx";

const drawerWidth: number = 180;

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(4),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(8),
      },
    }),
  },
}));

export default function DashboardMenu() {
  const { user } = React.useContext(UserContext);

  let navigate = useNavigate();

  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Drawer variant="permanent" open={open}>
      <Toolbar
        sx={{
          display: "flex",
          alignItems: "left",
          justifyContent: "flex-end",
          px: [1],
        }}
      >
        <IconButton onClick={toggleDrawer}>
          {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </Toolbar>
      <Divider sx={{ borderColor: "primary.light", borderWidth: 2 }} />
      <Tabs
        orientation="vertical"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        indicatorColor="secondary"
        textColor="secondary"
        sx={{
          borderRight: 1,
          borderColor: "divider",
          "&& .MuiTab-root": {
            justifyContent: "left",
          },
        }}
      >
        <Tab
          color="secondary.main"
          icon={
            <Tooltip title={open ? "" : "Overview"} placement="right" arrow>
              <DashboardIcon />
            </Tooltip>
          }
          iconPosition="start"
          label={open ? "Dashboard" : ""}
          onClick={() => {
            navigate("/dashboard/");
          }}
        />

        {(user.orgInfo.accountType === "manager" ||
          user.orgInfo.accountType === "admin") && (
          <Tab
            icon={
              <Tooltip title={open ? "" : "Library"} placement="right" arrow>
                <VideoLibraryIcon />
              </Tooltip>
            }
            iconPosition="start"
            label={open ? "Library" : ""}
            onClick={() => {
              navigate("/dashboard/library");
            }}
          />
        )}
        {(user.orgInfo.accountType === "manager" ||
          user.orgInfo.accountType === "admin") && (
          <Tab
            icon={
              <Tooltip title={open ? "" : "Teams"} placement="right" arrow>
                <GroupsIcon />
              </Tooltip>
            }
            iconPosition="start"
            label={open ? "Teams" : ""}
            onClick={() => {
              navigate("/dashboard/teams");
            }}
          />
        )}
        {(user.orgInfo.accountType === "manager" ||
          user.orgInfo.accountType === "admin") && (
          <Tab
            icon={
              <Tooltip title={open ? "" : "Reporting"} placement="right" arrow>
                <BarChartIcon />
              </Tooltip>
            }
            iconPosition="start"
            label={open ? "Reporting" : ""}
            onClick={() => {
              navigate("/dashboard/reporting");
            }}
          />
        )}
        {user.orgInfo.accountType === "admin" && (
          <Tab
            icon={
              <Tooltip title={open ? "" : "Admin"} placement="right" arrow>
                <LockPersonIcon />
              </Tooltip>
            }
            iconPosition="start"
            label={open ? "Admin" : ""}
            onClick={() => {
              navigate("/dashboard/admin");
            }}
          />
        )}
      </Tabs>

      <Divider sx={{ borderColor: "primary.light", borderWidth: 2, my: 1 }} />
      <br />
      <br />
      <br />

      <Tooltip
        title={
          <b style={{ whiteSpace: "pre-line" }}>
            {"Logged in as: " + user.email}
          </b>
        }
        placement="left"
        arrow
      >
        <Typography
          sx={{
            cursor: "pointer",
            position: "relative",
            fontWeight: "bold",
            color: "secondary.main",
            transform: "rotate(90deg)",
          }}
        >
          {user.orgInfo.displayName}
        </Typography>
      </Tooltip>
    </Drawer>
  );
}
