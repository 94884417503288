import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { useNavigate } from "react-router-dom";
import UserContext from "../Contexts/userContext.tsx";
import { useContext, useEffect } from "react";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialAction from "@mui/material/SpeedDialAction";

import LoginIcon from "@mui/icons-material/Login";
import LogoutIcon from "@mui/icons-material/Logout";
import DashboardIcon from "@mui/icons-material/Dashboard";
import Backdrop from "@mui/material/Backdrop";

import Drawer from "@mui/material/Drawer";

import LoginForm from "../Pages/Login/Login.tsx";
import Grid from "@mui/material/Grid";
import { Paper } from "@mui/material";

const pages = ["Contact Us"];

export default function MenuAppBar() {
  let navigate = useNavigate();
  const { user, logout } = useContext(UserContext);
  const handleOpen = () => setUserMenuOpen(true);
  const handleClose = () => setUserMenuOpen(false);

  const [userMenuOpen, setUserMenuOpen] = React.useState(false);
  const [actions, setActions] = React.useState<any[]>([]);

  useEffect(() => {
    if (user) {
      setLoginOpen(false);
      setActions([
        {
          icon: <DashboardIcon />,
          name: "Dashboard",
          action: () => {
            handleClose();
            navigate("/dashboard");
          },
        },
        {
          icon: <LogoutIcon />,
          name: "Logout",
          action: () => {
            logout(false);
            handleClose();
          },
        },
      ]);
    } else
      setActions([
        {
          icon: <LoginIcon />,
          name: "Login",
          action: () => {
            setLoginOpen(true);
            // navigate("/login");
            handleClose();
          },
        },
      ]);
  }, [user]);

  const [loginOpen, setLoginOpen] = React.useState(false);

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      event.preventDefault();
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setLoginOpen(open);
      handleClose();
    };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        elevation={3}
        position="sticky"
        sx={{ bgcolor: "primary.light", height: "50%", zIndex: 5000 }}
      >
        <Container maxWidth="xl">
          <Grid container spacing={0}>
            <Grid item xs={3}>
              <Toolbar disableGutters>
                <img
                  style={{ height: "50px" }}
                  src={require("../Images/Full_Icon_Transparent.png")}
                />
                <Typography
                  variant="h4"
                  noWrap
                  component="a"
                  href="/"
                  color="primary"
                  sx={{
                    mt: 0.4,
                    ml: 1,
                    mr: 2,
                    display: { xs: "none", md: "flex" },
                    fontWeight: 800,
                    // letterSpacing: ".3rem",
                    textDecoration: "none",
                  }}
                >
                  NextStep
                </Typography>
              </Toolbar>
            </Grid>
            <Grid item xs={6}>
              <Box
                sx={{
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  flexGrow: 1,
                  display: { xs: "none", md: "flex" },
                }}
              >
                {pages.map((page) => (
                  <Button
                    color="primary"
                    key={page}
                    onClick={() => {
                      switch (page) {
                        // case "About":
                        //   navigate("/about");
                        //   break;
                        case "Contact Us":
                          navigate("/contact");
                          break;
                      }
                      handleClose();
                    }}
                    size="large"
                    sx={{ my: 1.5, display: "block", fontWeight: "bold" }}
                  >
                    {page}
                  </Button>
                ))}
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Backdrop open={userMenuOpen} sx={{ bgColor: "#ffffff" }} />
              <SpeedDial
                ariaLabel="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                sx={{ position: "absolute", top: 5, right: 10 }}
                icon={<AccountCircle />}
                direction="down"
                onClose={handleClose}
                onOpen={handleOpen}
                open={userMenuOpen}
                FabProps={{
                  sx: { color: user ? "primary.main" : "divider" },
                  style: { backgroundColor: "white" },
                }}
              >
                {actions.map((action) => (
                  <SpeedDialAction
                    key={action.name}
                    icon={action.icon}
                    tooltipTitle={action.name}
                    tooltipOpen
                    onClick={action.action}
                    FabProps={{
                      sx: { color: "primary.main" },
                    }}
                  />
                ))}
              </SpeedDial>
            </Grid>
          </Grid>
        </Container>
      </AppBar>

      <React.Fragment key={"loginDrawer"}>
        <Drawer
          anchor={"right"}
          open={loginOpen}
          onClose={toggleDrawer(false)}
          sx={{ width: "800px" }}
        >
          <Box>
            <LoginForm />
          </Box>
        </Drawer>
      </React.Fragment>
    </Box>
  );
}
