import * as React from "react";
import { useTheme } from "@mui/material/styles";
import { LineChart, axisClasses } from "@mui/x-charts";
import { ChartsTextStyle } from "@mui/x-charts/ChartsText";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

function dateSummary(stepLog: any) {
  // this gives an object with dates as keys
  const groups = stepLog.reduce((groups, entry) => {
    const date = new Date(entry.timestamp).toDateString();
    if (!groups[date]) {
      groups[date] = 0;
    }
    groups[date] += +1;
    return groups;
  }, {});

  // Edit: to add it in the array format instead
  const groupArrays = Object.keys(groups).map((date) => {
    return {
      date: Date.parse(date),
      count: groups[date],
    };
  });

  return groupArrays;
}

export default function UsageChart(data: any) {
  let dateSummaryArray = dateSummary(data.stepHistory).sort(function (x, y) {
    return x.date - y.date;
  });

  const theme = useTheme();
  const yearFormater = (date: Date) =>
    new Date(date).toLocaleDateString("en-US");

  return (
    <React.Fragment>
      <Typography fontWeight="bold" color="primary.dark" variant="h5">
        Usage Over Time
      </Typography>
      {data.stepHistory.length > 0 ? (
        <div style={{ width: "100%", flexGrow: 1, overflow: "hidden" }}>
          <LineChart
            dataset={dateSummaryArray}
            margin={{
              top: 16,
              right: 20,
              left: 70,
              bottom: 40,
            }}
            xAxis={[
              {
                label: "Date",
                scaleType: "time",
                dataKey: "date",
                tickNumber: dateSummaryArray.length,
                tickLabelStyle: theme.typography.body2 as ChartsTextStyle,
                min: Math.min(dateSummaryArray[0].date),
                valueFormatter: yearFormater,
              },
            ]}
            yAxis={[
              {
                label: "Step Count",
                labelStyle: {
                  ...(theme.typography.body1 as ChartsTextStyle),
                  fill: theme.palette.text.primary,
                },
                tickLabelStyle: theme.typography.body2 as ChartsTextStyle,
                max: Math.max(
                  ...dateSummaryArray.map((object) => object.count as number)
                ),
                min: 0,
                tickNumber: 3,
              },
            ]}
            series={[
              {
                dataKey: "count",
                showMark: true,
                color: theme.palette.secondary.main,
              },
            ]}
            sx={{
              [`.${axisClasses.root} line`]: {
                stroke: theme.palette.text.secondary,
              },
              [`.${axisClasses.root} text`]: {
                fill: theme.palette.text.secondary,
              },
              [`& .${axisClasses.left} .${axisClasses.label}`]: {
                transform: "translateX(-25px)",
              },
            }}
          />
        </div>
      ) : (
        <Stack spacing={1}>
          <br />
          <Stack spacing={10} direction={"row"}>
            {/* make a nice graph skeleton */}
            <Skeleton variant="rectangular" width={10} height={150} />
            {[...Array(7)].map((e, i) => (
              <Skeleton
                key={i}
                variant="rounded"
                width={50}
                height={20 * (i + 1)}
                style={{ marginTop: 20 * (6 - i) }}
              />
            ))}
          </Stack>
          <Skeleton variant="rectangular" height={10} />
        </Stack>
      )}
    </React.Fragment>
  );
}
