import React, { useContext, useEffect } from "react";

import {
  Modal,
  Box,
  Alert,
  Typography,
  Stack,
  Button,
  Checkbox,
  ListItemButton,
  ListItemIcon,
  LinearProgress,
  TextField,
} from "@mui/material";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";

import { ModalStyle2 } from "../../../Components/modalStyle.tsx";

import {
  UpdateTeamMembers,
  GetOrgData,
} from "../../../firebase/getUserData.tsx";
import DashboardContext from "../../../Contexts/dashboardContext.tsx";
import UserContext from "../../../Contexts/userContext.tsx";
import { ModalList } from "../../../Config/styling.tsx";

export default function TeamMemberModal(props: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  member: any;
  team: any;
  action: string;
  token: string;
}) {
  const {
    orgMembersActive,
    setOrgMembers,
    setOrgSeqGroups,
    orgSeqGroups,
    orgCollectionsActive,
    setOrgTeams,
  } = useContext(DashboardContext);
  const { logout } = useContext(UserContext);

  /* org seq info */
  useEffect(() => {
    if (orgSeqGroups.length < 1) {
      GetOrgData(props.token, ["sequenceList"], logout).then((result: any) => {
        setOrgSeqGroups(result.sequenceGroups);
      });
    }
  }, []);

  const [teamSeqList, setTeamSeqList] = React.useState<any[]>([]);

  useEffect(() => {
    /* get list of sequence groups from collections tagged to the team */
    const teamCollections = props.team.collections || [];
    const fliteredCollections = orgCollectionsActive.filter((collection: any) =>
      teamCollections.includes(collection.id)
    );

    /* compress org groups into a single array */
    let filteredSeqGroups = [];
    for (let i = 0; i < fliteredCollections.length; i++) {
      filteredSeqGroups = filteredSeqGroups.concat(
        fliteredCollections[i].sequence_groups
      );
    }

    /* get a unique list of seq grounps */
    let uniquefilteredSeqGroups: any[] = [...new Set(filteredSeqGroups)];

    /* filter down the list of org sequences by the team seq groups and set state */
    const filteredorgSeqGroups = orgSeqGroups.filter((group: any) =>
      uniquefilteredSeqGroups.includes(Object.keys(group)[0])
    );

    const resObj = filteredorgSeqGroups.map(
      (each: any) => Object.values(each)[0]
    );

    setTeamSeqList(resObj);
  }, [orgSeqGroups]);

  const memberInfo = orgMembersActive.find(
    (item: any) => props.member === item.email
  );

  /* sequence access controls */
  const [selectedSeqList, setSelectedSeqList] = React.useState<number[]>(
    props.action === "edit" && memberInfo ? memberInfo.sequence_list : []
  );

  const handleToggleSequence = (value: number) => () => {
    const currentIndex = selectedSeqList.indexOf(value);
    const newSeqList = [...selectedSeqList];

    if (currentIndex === -1) {
      newSeqList.push(value);
    } else {
      newSeqList.splice(currentIndex, 1);
    }
    setSelectedSeqList(newSeqList);
  };

  /* member selection  controls */
  const [filteredMemList, setFilteredMemList] = React.useState<any[]>(
    orgMembersActive
      .map((member: any) => member.email)
      .filter((item: any) =>
        props.team.members
          ? !props.team.members.includes(item)
          : ![""].includes(item)
      )
  );

  const [newMemList, setNewMemList] = React.useState<number[]>([]);

  const handleToggleMembers = (value: number) => () => {
    const currentIndex = newMemList.indexOf(value);
    const tempMemList = [...newMemList];

    if (currentIndex === -1) {
      tempMemList.push(value);
    } else {
      tempMemList.splice(currentIndex, 1);
    }
    setNewMemList(tempMemList);
  };

  /* filter member list based on search */
  const handleSearchChange = (e: any) => {
    const tempMemList = [...newMemList];

    let filteredMemList = tempMemList.filter((member: any) =>
      member.toLowerCase().trim().includes(e.target.value.toLowerCase().trim())
    );
    setFilteredMemList(filteredMemList);
  };

  /* modal controls */
  const [modalMessage, setModalMessage] = React.useState<any>(null);
  const handleSubmit = () => {
    /* determine which sequces needed to be added / removed from member's profile.
    Cannot rip and replace since some assigned sequences may not be editable by the current team */

    /* get list of team sequence IDs */
    const teamSeqListIDs: number[] = [];
    teamSeqList.forEach((seqGroup: any) => {
      seqGroup.sequenceList.forEach((seq: any) => {
        teamSeqListIDs.push(seq.id);
      });
    });

    const submissionObj = {
      team: props.team.id,
      members: props.action === "add" ? newMemList : [props.member],
    };

    /* conditionally add sequece list */
    if (props.action === "add") {
      submissionObj["sequences"] = { add: selectedSeqList };
    } else if (props.action === "edit") {
      /* set seperate lists of sequences to add / remove */
      const seqToAddArr = selectedSeqList.filter(
        (x: number) =>
          !memberInfo.sequence_list.includes(x) && teamSeqListIDs.includes(x)
      );
      const seqToRemoveArr = teamSeqListIDs.filter(
        (x: number) =>
          memberInfo.sequence_list.includes(x) && !selectedSeqList.includes(x)
      );
      submissionObj["sequences"] = { add: seqToAddArr, remove: seqToRemoveArr };
    }

    setModalMessage(
      <Box sx={{ width: "100%" }}>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          {/* {props.info.message} */}
          Loading...
        </Typography>
        <br />
        <LinearProgress color="secondary" />
        <br />
      </Box>
    );

    UpdateTeamMembers(props.token, props.action, submissionObj, logout).then(
      (response: any) => {
        /* happy path */
        if (response.result === "success") {
          setModalMessage(
            <Box sx={{ width: "100%" }}>
              <Alert severity={"success"}>{"SUCCESS"}</Alert>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                {/* {props.info.message} */}
                {response.message}
              </Typography>
            </Box>
          );
          /* update the appropriate context so that changes are reflected */
          GetOrgData(props.token, ["teams"], logout).then((result: any) => {
            setOrgTeams(result.teams);
          });

          /* need to update org members so that changes to sequence assigments are reflected */
          GetOrgData(props.token, ["members"], logout).then((result: any) => {
            setOrgMembers(result.members);
          });

          setTimeout(() => {
            props.setOpen(false);
          }, 7000);

          /* unhappy path */
        } else {
          setModalMessage(
            <Box sx={{ width: "100%" }}>
              <Alert severity={"error"}>{"ERROR"}</Alert>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                {response.message}
              </Typography>
              <Button
                variant="outlined"
                fullWidth
                onClick={() => {
                  props.setOpen(false);
                }}
              >
                OKAY
              </Button>
            </Box>
          );
        }
      }
    );
  };

  return (
    <Modal
      open={props.open}
      onClose={() => {
        props.setOpen(false);
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={ModalStyle2}>
        {!modalMessage ? (
          <Box>
            {props.action === "remove" && (
              <Alert severity={"warning"}>{"Are you sure?"}</Alert>
            )}
            <Typography
              id="modal-modal-description"
              variant="h6"
              gutterBottom
              sx={{ mt: 1 }}
            >
              {props.action === "add" && (
                <span>
                  Select member(s) to add to{" "}
                  <b>{props.team.display_name || props.team.id}</b>
                </span>
              )}

              {props.action === "edit" && (
                <span>
                  Edit process assignments for <b>{props.member}</b>
                </span>
              )}

              {props.action === "remove" && (
                <span>
                  Are you sure you want to remove <b>{props.member}</b> from{" "}
                  <b>{props.team.display_name || props.team.id}</b>?
                </span>
              )}
              {props.action === "remove" && (
                <Typography
                  id="modal-modal-description"
                  variant="body1"
                  gutterBottom
                  sx={{ mt: 3, fontStyle: "italic" }}
                >
                  Note: removing a member from a team does not impact their
                  process asignments. To unassign sequences, use the 'edit'
                  button prior to removing the mamber from the team.
                </Typography>
              )}
            </Typography>
            <br />
            {props.action === "add" && (
              <MemberList
                memberList={filteredMemList}
                handleToggle={handleToggleMembers}
                newMemList={newMemList}
                handleSearchChange={handleSearchChange}
              />
            )}

            {(props.action === "add" || props.action === "edit") && (
              <SequenceList
                teamSeqList={teamSeqList}
                handleToggle={handleToggleSequence}
                selectedSeqList={selectedSeqList}
              />
            )}
            <br />
            <Stack direction="row" sx={{ bottom: 0 }}>
              <Button
                color="secondary"
                variant="contained"
                onClick={handleSubmit}
                fullWidth
                sx={{ m: 1 }}
              >
                SUBMIT
              </Button>
              <Button
                color="secondary"
                variant="outlined"
                fullWidth
                sx={{ m: 1 }}
                onClick={() => {
                  props.setOpen(false);
                }}
              >
                CANCEl
              </Button>
            </Stack>
          </Box>
        ) : (
          modalMessage
        )}
      </Box>
    </Modal>
  );
}

function SequenceList(props: {
  teamSeqList: any[];
  handleToggle: any;
  selectedSeqList: any;
}) {
  return (
    <Box>
      <Typography
        id="modal-modal-description"
        variant="h6"
        color="secondary"
        gutterBottom
        sx={{ mt: 1 }}
      >
        Processes
      </Typography>

      {/* sequence access */}
      {props.teamSeqList.length > 0 ? (
        // <ModalList></ModalList>
        <ModalList>
          {props.teamSeqList.map((group) => (
            <li key={`section-${group.id}`}>
              {/* if there are no sequences, don't show the group header */}
              {group.sequenceList.length > 0 && (
                <ul>
                  <ListSubheader>{group.displayName}</ListSubheader>
                  {group.sequenceList.map((sequence: any) => (
                    <ListItem
                      key={`item-${group.id}-${sequence.id}`}
                      sx={{ padding: 0 }}
                      dense
                    >
                      <ListItemButton
                        role={undefined}
                        onClick={props.handleToggle(sequence.id)}
                        dense
                      >
                        <ListItemIcon>
                          <Checkbox
                            color="secondary"
                            edge="start"
                            checked={
                              props.selectedSeqList.indexOf(sequence.id) !== -1
                            }
                            tabIndex={-1}
                            disableRipple
                            inputProps={{
                              "aria-labelledby": sequence.name,
                            }}
                          />
                        </ListItemIcon>
                        <ListItemText primary={sequence.name} />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ModalList>
      ) : (
        <LinearProgress color="secondary" />
      )}
    </Box>
  );
}

function MemberList(props: {
  memberList: any[];
  handleToggle: any;
  newMemList: any;
  handleSearchChange: any;
}) {
  return (
    <Box>
      <Typography
        id="modal-modal-description"
        variant="h6"
        color="secondary"
        gutterBottom
        sx={{ mt: 1 }}
      >
        Organization Members
      </Typography>

      <TextField
        id="outlined-search"
        label="Search for member"
        type="search"
        size="small"
        style={{
          width: "100%",
          background: "#FFFFFF",
        }}
        onChange={props.handleSearchChange}
      />

      {/* member access */}
      {props.memberList.length > 0 ? (
        <ModalList>
          {props.memberList.map((member) => (
            <ListItem key={`item-${member}`} sx={{ padding: 0 }} dense>
              <ListItemButton
                role={undefined}
                onClick={props.handleToggle(member)}
                dense
              >
                <ListItemIcon>
                  <Checkbox
                    color="secondary"
                    edge="start"
                    checked={props.newMemList.indexOf(member) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{
                      "aria-labelledby": member,
                    }}
                  />
                </ListItemIcon>
                <ListItemText primary={member} />
              </ListItemButton>
            </ListItem>
          ))}
        </ModalList>
      ) : (
        <i>No members available to add</i>
      )}
    </Box>
  );
}
