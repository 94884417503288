import React, { useContext, useEffect } from "react";

import {
  Autocomplete,
  Box,
  CssBaseline,
  LinearProgress,
  TextField,
  Typography,
} from "@mui/material";
import DashboardContext from "../../../../Contexts/dashboardContext.tsx";
import { client } from "../../../../Config/styling.tsx";

export default function SeqGroupItem(props: {
  seqGroupId: string;
  setSeqGroupId: React.Dispatch<string>;
  seqGroupOptions: any;
  setSeqGroupOptions: React.Dispatch<any>;
  isEdit: boolean;
  collection: any;
}) {
  const { orgCollectionsActive } = useContext(DashboardContext);

  const [newCollection, setNewCollection] = React.useState<any>(
    props.collection
  );
  const handleCollectionChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: any,
    reason: any
  ) => {
    if (reason === "clear") {
      setNewCollection(null);
    } else {
      setNewCollection(value);
      props.setSeqGroupOptions({
        ...props.seqGroupOptions,
        newCollection: value.id,
        currentCollection: props.collection.id,
      });
    }
  };

  useEffect(() => {
    if (!props.seqGroupOptions.newCollection) {
      props.setSeqGroupOptions({
        ...props.seqGroupOptions,
        newCollection: props.collection.id,
      });
    }
  });

  return (
    <Box>
      <Box sx={{ minWidth: 120 }}>
        <TextField
          color="secondary"
          id="seqGroupName"
          label="Process Group Name"
          value={props.seqGroupOptions.displayName || ""}
          type="search"
          variant="standard"
          multiline
          fullWidth
          margin="dense"
          onChange={(event: any) => {
            props.setSeqGroupOptions({
              ...props.seqGroupOptions,
              displayName: event.target.value as string,
            });
          }}
        />
      </Box>

      <br />
      {props.isEdit && (
        <Box>
          <Typography
            id="select-collection-new-sequence-group"
            variant="h6"
            color="secondary"
            gutterBottom
            sx={{ mt: 1 }}
          >
            Transfer this Process Group to another Collection (optional)
          </Typography>

          {orgCollectionsActive ? (
            <Box>
              <CssBaseline />
              <Autocomplete
                color="secondary"
                disablePortal
                id="memberSelect"
                options={orgCollectionsActive}
                value={newCollection}
                onChange={handleCollectionChange}
                sx={{ width: "100%", bgcolor: "white" }}
                slotProps={client.Autocomplete_SlotProps}
                getOptionLabel={(option: any) =>
                  option.display_name || option.id
                }
                isOptionEqualToValue={(option, value) =>
                  option.id === value.id || false
                }
                renderInput={(collections) => (
                  <TextField
                    {...collections}
                    color="secondary"
                    label="Collections"
                  />
                )}
              />
            </Box>
          ) : (
            <LinearProgress color="secondary" />
          )}
        </Box>
      )}
      <br />
    </Box>
  );
}
