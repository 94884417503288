import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

const steps = [
  {
    title: "Capture",
    subtitle: "Step 1",
    description: "Record the processes your members need to know",
  },
  {
    title: "Configure",
    subtitle: "Step 2",
    description:
      "Leverage user and role based access to provide a customized experience",
  },
  {
    title: "Deliver",
    subtitle: "Step 3",
    description:
      "Members have access to the information they need, right when they need it",
  },
];

const finalMessage = "This is the final message";

export default function OverviewStepper() {
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Box sx={{ mx: 20 }}>
      <Stepper activeStep={activeStep}>
        {steps.map((step, index) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: {
            optional?: React.ReactNode;
          } = {};
          labelProps.optional = (
            <Typography variant="caption">{step.subtitle}</Typography>
          );

          return (
            <Step key={step.title} {...stepProps}>
              <StepLabel {...labelProps}>{step.title}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <React.Fragment>
        <Typography sx={{ mt: 8, mb: 5, fontSize: "30px" }}>
          {activeStep === steps.length
            ? finalMessage
            : steps[activeStep].description}
        </Typography>
        <Box sx={{ display: "flex", mx: "auto", flexDirection: "row", pt: 2 }}>
          <Box sx={{ flex: "1 1 auto" }} />
          <Button
            size="large"
            color="inherit"
            disabled={activeStep === 0}
            onClick={handleBack}
            sx={{ mr: 1 }}
          >
            Previous
          </Button>

          <Button
            size="large"
            disabled={activeStep === steps.length}
            onClick={handleNext}
          >
            {activeStep === steps.length - 1 ? "Finish" : "Next"}
          </Button>
          <Box sx={{ flex: "1 1 auto" }} />
        </Box>
      </React.Fragment>
    </Box>
  );
}
