import React, { useEffect } from "react";

import {
  Stack,
  FormControl,
  TextField,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
  Paper,
  Button,
  Divider,
  Box,
  Tooltip,
  ToggleButton,
} from "@mui/material";

import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";

import { builder_step } from "./types";

export default function StepForm(props: {
  index: number;
  stepArray: builder_step[];
  setStepArray: React.Dispatch<React.SetStateAction<builder_step[]>>;
}) {
  /* defaultValues used on initial load and also when data is copied in from the builder */
  const defaultValues = {
    anchorElement: { selector: "", innerText: "", disableOutline: false },
    nextTrigger: "onPageNav",
    dialogBox: { width: 300, translateX: 0, translateY: 0 },
  } as builder_step;

  const [formStep, setFormStep] = React.useState<builder_step>(defaultValues);

  const [isPasteEnabled, setIsPasteEnabled] = React.useState<boolean>(false);
  useEffect(() => {
    /* set a listener for when the page focus changes */
    var handler = function (event: any) {
      if (document.hasFocus()) {
        CheckClipboard(setIsPasteEnabled);
      }
    };
    document.addEventListener("focus", handler, true);

    /* also check the clip status on first load */
    CheckClipboard(setIsPasteEnabled);
  }, []);

  useEffect(() => {
    setFormStep({
      ...defaultValues,
      ...props.stepArray[props.index],
    });
  }, [props.index]);

  useEffect(() => {
    let newStepArray = JSON.parse(JSON.stringify(props.stepArray));
    newStepArray[props.index] = formStep;
    props.setStepArray(newStepArray);
  }, [formStep]);

  const handleInputChange = (e: any) => {
    /* here we update the stepArray state without using setStepArray because we do not want a re-render on input changes */
    let property = e.target.id;

    /* need to update both the local formStep (for rendering) and the inherited stepArray (for changes from above) */
    const tempFormStep = JSON.parse(JSON.stringify(formStep));
    if (
      property &&
      ["width", "translateX", "translateY"].includes(property as string)
    ) {
      tempFormStep.dialogBox[property] = Number(e.target.value);
    } else if (
      property &&
      ["selector", "innerText"].includes(property as string)
    ) {
      tempFormStep.anchorElement[property] = e.target.value;
    } else {
      tempFormStep[property] = e.target.value;
    }

    setFormStep(tempFormStep);
  };

  const handleRadioChange = (e: any) => {
    /* here we update the stepArray state without using setStepArray because we do not want a re-render on input changes */
    let property = "nextTrigger";

    /* need to update both the local formStep (for rendering) and the inherited stepArray (for changes from above) */
    const tempFormStep = JSON.parse(JSON.stringify(formStep));
    tempFormStep[property] = e.target.value;

    setFormStep(tempFormStep);
  };

  /* paste in the config info from the step builder page in the extension */
  const pasteConfig = () => {
    navigator.clipboard.readText().then((clipText) => {
      try {
        const configInfo = JSON.parse(clipText);

        const tempStep: builder_step = {
          url: configInfo.url || "",
          anchorElement:
            configInfo.anchorElement || defaultValues.anchorElement,
          title: configInfo.title || "",
          subtitle: configInfo.subtitle || "",
          description: configInfo.description || "",
          dialogBox: configInfo.dialogBox || defaultValues.dialogBox,
          nextTrigger: configInfo.nextTrigger || defaultValues.nextTrigger,
          isFirstStep: configInfo.isFirstStep || false,
          isLastStep: configInfo.isLastStep || false,
          contextDiv: configInfo.contextDiv || "",
        };

        setFormStep(tempStep);
      } catch {
        alert("Unable to paste step information");
      }
    });
  };

  return (
    <Paper
      elevation={4}
      sx={{
        p: 2,
        m: 2,
        display: "flex",
        flexDirection: "column",
        bgcolor: "secondary2.light",
        border: props.index !== null ? 4 : 0,
        borderColor: "secondary.main",
      }}
    >
      <form id="StepEditorForm">
        <Stack spacing={1}>
          <Button
            color="secondary"
            variant="outlined"
            size="small"
            onClick={pasteConfig}
            fullWidth={true}
            disabled={!isPasteEnabled}
          >
            Paste from Clipboard
          </Button>
          <TextField
            id="url"
            label="Url"
            value={formStep.url || ""}
            color="secondary"
            type="search"
            size="small"
            margin="dense"
            multiline
            style={{
              width: "100%",
              background: "#FFFFFF",
            }}
            onChange={handleInputChange}
          />
          <Divider
            sx={{
              fontSize: "small",
              mt: 2,
              color: "secondary.dark",
            }}
          >
            Anchor Element
          </Divider>
          <Stack direction="row">
            <TextField
              id="selector"
              label="Selector"
              required
              value={formStep.anchorElement?.selector || ""}
              color="secondary"
              type="search"
              size="small"
              margin="dense"
              multiline
              style={{
                width: "100%",
                background: "#FFFFFF",
              }}
              onChange={handleInputChange}
            />
            <Box margin={"auto"}>
              <Box mt={"5px"}>
                <Tooltip
                  placement="top-end"
                  arrow
                  title={
                    formStep.anchorElement!.disableOutline
                      ? "Show Outline"
                      : "Hide Outline"
                  }
                >
                  <ToggleButton
                    size="small"
                    id="disableOutline"
                    value="disableOutline"
                    selected={!formStep.anchorElement?.disableOutline || false}
                    onChange={() => {
                      const tempFormStep = JSON.parse(JSON.stringify(formStep));
                      tempFormStep["disableOutline"] =
                        !tempFormStep["disableOutline"];

                      setFormStep(tempFormStep);
                    }}
                    sx={{
                      p: "5px",
                      ml: 1,
                      borderColor: "secondary2.light",
                      borderStyle: "solid",
                      borderWidth: 3,
                      "&.Mui-selected, &.Mui-selected:hover": {
                        borderColor: "secondary.main",
                      },
                    }}
                  >
                    {formStep.anchorElement?.disableOutline ? (
                      <DoNotDisturbIcon color="info" />
                    ) : (
                      <CheckCircleOutlineIcon color="info" />
                    )}
                  </ToggleButton>
                </Tooltip>
              </Box>
            </Box>
          </Stack>
          <TextField
            id="innerText"
            label="Text"
            value={formStep.anchorElement?.innerText || ""}
            color="secondary"
            type="search"
            size="small"
            margin="dense"
            multiline
            style={{
              width: "100%",
              background: "#FFFFFF",
            }}
            onChange={handleInputChange}
          />

          <Divider
            sx={{
              fontSize: "small",
              mt: 2,
              color: "secondary.dark",
            }}
          >
            Step Details
          </Divider>
          <TextField
            id="title"
            label="Title"
            required
            value={formStep.title || ""}
            color="secondary"
            type="search"
            size="small"
            margin="dense"
            multiline
            style={{
              width: "100%",
              background: "#FFFFFF",
            }}
            onChange={handleInputChange}
          />
          <TextField
            id="subtitle"
            label="Subtitle"
            value={formStep.subtitle || ""}
            color="secondary"
            type="search"
            size="small"
            margin="dense"
            multiline
            style={{
              width: "100%",
              background: "#FFFFFF",
            }}
            onChange={handleInputChange}
          />
          <TextField
            id="description"
            label="Description"
            required
            value={formStep.description || ""}
            color="secondary"
            type="search"
            size="small"
            margin="dense"
            multiline
            style={{
              width: "100%",
              background: "#FFFFFF",
            }}
            onChange={handleInputChange}
          />
          <Divider
            sx={{
              fontSize: "small",
              mt: 2,
              color: "secondary.dark",
            }}
          >
            Additional Options
          </Divider>
          <Stack direction="row" spacing={2}>
            <TextField
              id="width"
              label="Width"
              value={
                formStep.dialogBox?.width || defaultValues.dialogBox?.width
              }
              color="secondary"
              type="number"
              size="small"
              margin="dense"
              style={{
                width: "33%",
                background: "#FFFFFF",
              }}
              onChange={handleInputChange}
            />
            <TextField
              id="translateX"
              label="Move right (px)"
              value={
                formStep.dialogBox?.translateX ||
                defaultValues.dialogBox?.translateX
              }
              color="secondary"
              type="number"
              size="small"
              margin="dense"
              style={{
                width: "33%",
                background: "#FFFFFF",
              }}
              onChange={handleInputChange}
            />
            <TextField
              id="translateY"
              label="Move down (px)"
              value={
                formStep.dialogBox?.translateY ||
                defaultValues.dialogBox?.translateY
              }
              color="secondary"
              type="number"
              size="small"
              margin="dense"
              style={{
                width: "33%",
                background: "#FFFFFF",
              }}
              onChange={handleInputChange}
            />
          </Stack>
          <FormControl color="secondary">
            <FormLabel id="buttons-group-label">
              How will the user navigate to the next step in the process?
            </FormLabel>
            <RadioGroup
              color="secondary"
              id="nextTrigger"
              aria-labelledby="buttons-group-label"
              name="radio-buttons-group"
              value={formStep.nextTrigger || defaultValues.nextTrigger}
              onChange={handleRadioChange}
            >
              <FormControlLabel
                value="onPageNav"
                control={<Radio color="secondary" />}
                label="By clicking the relevant page element"
                componentsProps={{ typography: { variant: "body2" } }}
              />
              <FormControlLabel
                value="dialogButton"
                control={<Radio color="secondary" />}
                label="By clicking the 'next' button on the popup"
                componentsProps={{ typography: { variant: "body2" } }}
              />
              <FormControlLabel
                value="userNav"
                control={<Radio color="secondary" />}
                label="By navigating to the next page"
                componentsProps={{ typography: { variant: "body2" } }}
              />
            </RadioGroup>
          </FormControl>
          <TextField
            id="contextDiv"
            label="Reference element"
            value={formStep.contextDiv || ""}
            color="secondary"
            type="search"
            size="small"
            margin="dense"
            style={{
              width: "100%",
              background: "#FFFFFF",
            }}
            onChange={handleInputChange}
          />
        </Stack>
      </form>
    </Paper>
  );
}

function CheckClipboard(setIsClipStep: any) {
  /* check if the contents of the clipboard conform to the 'step' data formant and set the state accordingly */
  try {
    /* throw an error if the page is not in focus */
    if (!document.hasFocus()) {
      throw Error;
    }

    navigator.clipboard.readText().then((clipText) => {
      /* also throw an error if the contents of the clipboard are not valid JSON */
      try {
        JSON.parse(clipText);

        const subjectArr = Object.keys(JSON.parse(clipText));
        const testArr = ["anchorElement", "title", "description", "dialogBox"];

        let checker = (arr: string[], target: string[]) =>
          target.every((v: string) => arr.includes(v));

        setIsClipStep(checker(subjectArr, testArr));
      } catch {
        setIsClipStep(false);
      }
    });
  } catch {
    setIsClipStep(false);
  }
}
