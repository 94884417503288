import React, { useContext } from "react";

import {
  Modal,
  Box,
  Alert,
  Typography,
  Stack,
  Button,
  LinearProgress,
} from "@mui/material";

import { ModalStyle2 } from "../../../Components/modalStyle.tsx";

import {
  EditOrgItem,
  GetOrgData,
  AddEditSequeceGroup,
} from "../../../firebase/getUserData.tsx";
import DashboardContext from "../../../Contexts/dashboardContext.tsx";
import UserContext from "../../../Contexts/userContext.tsx";

import MemberItem from "./modalComponents/memberItem.tsx";
import CollectionItem from "./modalComponents/collectionItem.tsx";
import TeamItem from "./modalComponents/teamItem.tsx";
import SequenceGroupItem from "./modalComponents/sequenceGroupItem.tsx";

export default function EditItemModal(props: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  itemType: string;
  item: any;
  token: string;
  parentItem?: any;
}) {
  const { setOrgMembers, setOrgCollections, setOrgTeams, setOrgSeqGroups } =
    useContext(DashboardContext);
  const { logout } = useContext(UserContext);

  /* item type controls */
  const [itemOptions, setItemOptions] = React.useState<any>(props.item);
  const [itemId, setItemId] = React.useState<string>(props.item.id);

  /* modal controls */
  const [modalMessage, setModalMessage] = React.useState<any>(null);

  const handleSubmit = () => {
    setModalMessage(
      <Box sx={{ width: "100%" }}>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          {/* {props.info.message} */}
          Loading...
        </Typography>
        <br />
        <LinearProgress color="secondary" />
        <br />
      </Box>
    );

    let options = JSON.parse(JSON.stringify(itemOptions));
    delete options["id"];

    const getData =
      props.itemType === "sequence-group"
        ? AddEditSequeceGroup(
            props.token,
            "edit",
            { item: props.item.id, ...options },
            logout
          )
        : EditOrgItem(
            props.token,
            props.itemType,
            props.item.id,
            options,
            logout
          );
    getData.then((response: any) => {
      /* happy path */
      if (response.result === "success") {
        setModalMessage(
          <Box sx={{ width: "100%" }}>
            <Alert severity={"success"}>{"SUCCESS"}</Alert>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              {response.message}
            </Typography>
          </Box>
        );

        /* update the appropriate context so that changes are reflected */
        if (props.itemType === "member") {
          GetOrgData(props.token, ["members"], logout).then((result: any) => {
            setOrgMembers(result.members);
          });
        } else if (props.itemType === "team") {
          GetOrgData(props.token, ["teams"], logout).then((result: any) => {
            setOrgTeams(result.teams);
          });
        } else if (props.itemType === "collection") {
          GetOrgData(props.token, ["collections"], logout).then(
            (result: any) => {
              setOrgCollections(result.collections);
            }
          );
        } else if (props.itemType === "sequence-group") {
          GetOrgData(props.token, ["sequenceList", "collections"], logout).then(
            (result: any) => {
              setOrgSeqGroups(result.sequenceGroups);
              setOrgCollections(result.collections);
            }
          );
        }

        setTimeout(() => {
          props.setOpen(false);
        }, 7000);

        /* unhappy path */
      } else {
        setModalMessage(
          <Box sx={{ width: "100%" }}>
            <Alert severity={"error"}>{"ERROR"}</Alert>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              {/* {props.info.message} */}
              {response.message}
            </Typography>
            <Button
              variant="outlined"
              fullWidth
              onClick={() => {
                props.setOpen(false);
              }}
            >
              OKAY
            </Button>
          </Box>
        );
      }
    });
  };

  return (
    <Modal
      open={props.open}
      onClose={() => {
        props.setOpen(false);
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={ModalStyle2}>
        {!modalMessage ? (
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography
              id="add-modal-description"
              variant="h6"
              color="secondary"
              gutterBottom
              sx={{ mt: 1 }}
            >
              Edit{" "}
              {props.itemType === "sequence-group"
                ? "process group"
                : props.itemType}{" "}
              settings:
            </Typography>
            {props.itemType === "member" && (
              <MemberItem
                memberId={itemId}
                setMemberId={setItemId}
                memberOptions={itemOptions}
                setMemberOptions={setItemOptions}
                isEdit={true}
              />
            )}
            {props.itemType === "collection" && (
              <CollectionItem
                collectionId={itemId}
                setCollectionId={setItemId}
                collectionOptions={itemOptions}
                setCollectionOptions={setItemOptions}
                isEdit={true}
              />
            )}
            {props.itemType === "team" && (
              <TeamItem
                teamId={itemId}
                setTeamId={setItemId}
                teamOptions={itemOptions}
                setTeamOptions={setItemOptions}
                isEdit={true}
              />
            )}
            {props.itemType === "sequence-group" && (
              <SequenceGroupItem
                seqGroupId={itemId}
                setSeqGroupId={setItemId}
                seqGroupOptions={itemOptions}
                setSeqGroupOptions={setItemOptions}
                isEdit={true}
                collection={props.parentItem}
              />
            )}
            <br />
            <Stack direction="row">
              <Button
                color="secondary"
                variant="contained"
                onClick={handleSubmit}
                fullWidth
                sx={{ m: 1, bottom: 0 }}
              >
                SUBMIT
              </Button>
              <Button
                color="secondary"
                variant="outlined"
                fullWidth
                sx={{ m: 1 }}
                onClick={() => {
                  props.setOpen(false);
                }}
              >
                CANCEl
              </Button>
            </Stack>
          </Box>
        ) : (
          modalMessage
        )}
      </Box>
    </Modal>
  );
}
