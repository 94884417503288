import React from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { Button, Divider, Stack, Typography } from "@mui/material";

import OverviewStepper from "./overviewStepper.tsx";
import Footer from "../../Components/footer.tsx";

import { config } from "../../Config/config.tsx";

const impactCards: any = [
  {
    title: "New Users",
    description: "Learn processes by performing them in the your systems",
  },
  {
    title: "New Tools",
    description:
      "Effectively and efficiently distribute technology transformations",
  },
  {
    title: "Reference Guides",
    description:
      "Readily available access to complex, low-use and key processes",
  },
];

export default function HomePage() {
  return (
    <Stack>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />

        <Box
          component="main"
          sx={{
            backgroundColor: "primary.light",
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Container
            maxWidth={false}
            disableGutters={true}
            sx={{ p: 0, mt: 0, mb: 4, width: "100%" }}
          >
            <Paper
              elevation={0}
              sx={{
                pt: 12,
                pb: 30,
                display: "flex",
                flexDirection: "column",

                width: "100%",
              }}
            >
              <br />
              <Grid container spacing={3}>
                <Grid item xs={6} alignContent="center">
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <Typography variant="h3" color="primary.dark" align="right">
                    Knowledge Contextualized
                  </Typography>
                  <Typography
                    variant="h3"
                    color="primary"
                    fontWeight="bold"
                    align="right"
                  >
                    learn by doing
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Paper
                    elevation={2}
                    sx={{
                      p: 7,
                      width: "600px",
                      backgroundColor: "primary.light",
                      borderRadius: "50px",
                    }}
                  >
                    <Typography
                      variant="h4"
                      color="primary.dark"
                      align="left"
                      fontWeight="bold"
                    >
                      A new approach to knowledge management
                    </Typography>
                    <br />
                    <Typography variant="h6" color="primary.dark" align="left">
                      From user onboarding to continuing eductaion, effective
                      knowledge management is critical to the success of any
                      organization
                    </Typography>
                    <br />
                    <Typography variant="h6" color="primary.dark" align="left">
                      NextStep provides step-by-step interactive training
                      directly in your web-based applications.
                    </Typography>
                  </Paper>
                </Grid>
              </Grid>
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
            </Paper>
          </Container>

          {/* this container is the lower gray part behind the browser window */}
          <Container
            maxWidth={false}
            sx={{
              mt: -30,
              mb: 10,
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* browser window */}
            <Paper
              elevation={12}
              sx={{
                display: "flex",
                flexDirection: "column",
                height: 500,
                width: "60%",
                backgroundColor: "divider",
              }}
            >
              <Box
                sx={{
                  m: ".5%",
                  width: "99%",
                  height: "30px",
                  backgroundColor: "divider",
                }}
              >
                <Grid container spacing={3}>
                  <Grid item xs={3}>
                    <Stack direction="row">
                      <Box
                        sx={{
                          m: 1,
                          borderRadius: "50%",
                          height: "15px",
                          width: "15px",
                          backgroundColor: "#EC6A5F",
                        }}
                      />
                      <Box
                        sx={{
                          my: 1,
                          mx: 0.5,
                          borderRadius: "50%",
                          height: "15px",
                          width: "15px",
                          backgroundColor: "#F4BF4F",
                        }}
                      />
                      <Box
                        sx={{
                          m: 1,
                          borderRadius: "50%",
                          height: "15px",
                          width: "15px",
                          backgroundColor: "#61C554",
                        }}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      align="left"
                      sx={{
                        pl: 0.5,
                        borderRadius: "10px",
                        color: "primary.dark",
                        backgroundColor: "lightgray",
                        m: 0.5,
                        width: "100%",
                        height: "24px",
                        overflow: "hidden",
                      }}
                    >
                      https://www.nextstepknowledge.com
                    </Typography>
                  </Grid>
                  <Grid item xs={3}></Grid>
                </Grid>
              </Box>
              <Box
                sx={{
                  mx: ".5%",
                  width: "99%",
                  height: 460,
                  backgroundColor: "white",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "left",
                }}
              >
                <Stack sx={{ p: 2 }}>
                  <Typography color="primary.dark" variant="h4" align="left">
                    New users.
                  </Typography>
                  <Typography color="primary.dark" variant="h4" align="left">
                    Continual learning.
                  </Typography>
                  <Typography color="primary.dark" variant="h4" align="left">
                    Accessible reference material.
                  </Typography>
                  <Typography
                    color="primary"
                    variant="h4"
                    fontWeight="bold"
                    align="left"
                  >
                    All in the context of your browser.
                  </Typography>
                </Stack>
              </Box>
            </Paper>
          </Container>

          <Container
            maxWidth={false}
            sx={{
              width: "70%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Stack width="70%">
              <Stack
                direction="row"
                width="100%"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Typography variant="h4" color="primary.dark">
                  Expirence
                  <Typography
                    px={1}
                    component="span"
                    variant="inherit"
                    color="primary"
                    fontWeight="bold"
                  >
                    NextStep
                  </Typography>
                  in action
                </Typography>
              </Stack>

              <Stack direction="row">
                {" "}
                <Button
                  fullWidth
                  variant="contained"
                  size="large"
                  href={config.chromeExtensionURL}
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{
                    m: 2,
                    backgroundColor: "primary.dark",
                    "&:hover": {
                      backgroundColor: "primary.main",
                    },
                  }}
                >
                  Install on Chrome
                </Button>
                <Button
                  fullWidth
                  variant="contained"
                  size="large"
                  href={config.edgeExtensionUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{
                    m: 2,
                  }}
                >
                  Install on Edge
                </Button>
              </Stack>
              <br />
              <br />
            </Stack>
          </Container>

          <Container
            maxWidth={false}
            disableGutters={true}
            sx={{ my: 4, width: "100%" }}
          >
            <Paper
              elevation={0}
              sx={{
                p: 10,
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography color="primary" variant="h3">
                    Impact
                  </Typography>
                  <br />
                  <Typography color="primary.dark" variant="h6">
                    Provide a unique training experience that enables your users
                    to
                    <Typography
                      px={1}
                      component="span"
                      variant="inherit"
                      color="primary"
                    >
                      learn by doing.
                    </Typography>
                    We make knowledge highly interactive and accessible through
                    a step-by-step interactive process directly for your
                    web-based applications.
                  </Typography>
                  <br />
                </Grid>
                <Grid item xs={1.5}></Grid>

                {impactCards.map((card: any) => {
                  return (
                    <Grid key={card.title} item xs={3}>
                      <Paper
                        elevation={4}
                        sx={{
                          bgcolor: "primary.dark",
                          p: 3,
                          display: "flex",
                          flexDirection: "column",
                          height: 240,
                          width: "100%",
                        }}
                      >
                        <Typography
                          color="primary"
                          fontWeight="bold"
                          variant="h5"
                        >
                          {card.title}
                        </Typography>
                        <br />
                        <Divider />
                        <br />
                        <Typography color="white" variant="body1">
                          {card.description}
                        </Typography>
                      </Paper>
                    </Grid>
                  );
                })}
                <Grid item xs={1.5}></Grid>
              </Grid>
            </Paper>
          </Container>

          <Container maxWidth={false} sx={{ p: 0, my: 4, width: "100%" }}>
            <Typography color="primary.dark" variant="h4">
              How it works
            </Typography>
            <br />
            <br />
            <OverviewStepper />
          </Container>
          <br />
          <br />
          <br />
          <Footer />
        </Box>
      </Box>
    </Stack>
  );
}
