import React from "react";

import { Modal, Box, Alert, Typography, Stack, Button } from "@mui/material";
import { useContext } from "react";
import AppContext from "../Contexts/appContext.tsx";

import { ModalStyle } from "./modalStyle.tsx";

interface errorModalProps {
  info: any;
}

export default function ErrorModal(props: errorModalProps) {
  const { setModalInfo } = useContext(AppContext);
  return (
    <Modal
      open={props.info.open}
      onClose={() => {
        setModalInfo({ open: false });
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={ModalStyle}>
        <Alert severity={props.info.severity || "error"}>
          {props.info.severity ? props.info.severity.toUpperCase() : "ERROR"}
        </Alert>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          {props.info.message}
        </Typography>
        <Stack sx={{ bottom: 0 }}>
          <br />
          {!props.info.hideButton && (
            <Button
              color="secondary"
              variant="outlined"
              hidden={props.info.hideButton || false}
              onClick={() => {
                setModalInfo({ open: false });
              }}
            >
              Close
            </Button>
          )}
        </Stack>
      </Box>
    </Modal>
  );
}
