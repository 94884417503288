import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import UsageChart from "./chart.tsx";
import TopSequences from "./card.tsx";
import { useContext } from "react";
import DashboardContext from "../../../Contexts/dashboardContext.tsx";

import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import UsageBarChart from "./usageBarChart.tsx";
import { client } from "../../../Config/styling.tsx";
import { Box } from "@mui/material";

export default function Overview() {
  const { stepHistory, orgCollections, orgTeams, orgMembers } =
    useContext(DashboardContext);

  const [stepLog, setStepLog] = React.useState<any[]>(stepHistory.stepLog);
  React.useEffect(() => {
    setStepLog(stepHistory.stepLog);
  }, [stepHistory]);

  /* collection controls */
  const [collectionOptions, setCollectionOptions] =
    React.useState<any[]>(orgCollections);
  const [selectedCollection, setSelectedCollection] = React.useState<any>(null);
  React.useEffect(() => {
    setCollectionOptions(orgCollections);
  }, [orgCollections]);
  /* team controls */
  const [teamOptions, setTeamOptions] = React.useState<any[]>(orgTeams || []);
  const [selectedTeam, setSelectedTeam] = React.useState<any>(null);
  React.useEffect(() => {
    setTeamOptions(orgTeams);
  }, [orgTeams]);
  /* member controls */
  const [memberOptions, setMemberOptions] = React.useState<any[]>(
    orgMembers || []
  );
  const [selectedMember, setSelectedMember] = React.useState<any>(null);
  React.useEffect(() => {
    setMemberOptions(orgMembers);
  }, [orgMembers]);

  const handleCollectionChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: any,
    reason: any
  ) => {
    if (reason === "clear") {
      setSelectedCollection(null);
      setSelectedTeam(null);
      setSelectedMember(null);
      setTeamOptions(orgTeams);
      setMemberOptions(orgMembers);
    } else {
      setSelectedCollection(value);
      setSelectedTeam(null);
      setSelectedMember(null);

      /* filter and set teams */
      const filteredTeams = orgTeams.filter((team: any) =>
        team.collections.includes(value.id)
      );
      setTeamOptions(filteredTeams);

      /* need to split out the logic for filtering members so that it can be called again if the team filter is cleared */
      applyCollectionMemberFilter(filteredTeams);
    }
  };

  const applyCollectionMemberFilter = (teams: any) => {
    /* filter and set members */
    const flatMemArr: any[] = orgMembers.map((mem: any) => mem.email);

    let collectionFilteredMembers: any[] = [];
    teams.forEach((team: any) => {
      if (team.members) {
        const teamFilteredMembers: any = flatMemArr.filter((member: string) =>
          team.members.includes(member)
        );
        collectionFilteredMembers.push(...teamFilteredMembers);
      }
    });

    /* collectionFilteredMembers is now a flat array of strings; need to 'fluff it back up' */
    const unflattenedMembers = orgMembers.filter((member: any) =>
      collectionFilteredMembers.includes(member.email)
    );
    setMemberOptions(unflattenedMembers);
  };

  const handleTeamChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: any,
    reason: any
  ) => {
    if (reason === "clear") {
      setSelectedTeam(null);
      setSelectedMember(null);
      setMemberOptions(orgMembers);
      applyCollectionMemberFilter(teamOptions);
    } else {
      setSelectedTeam(value);
      setSelectedMember(null);

      /* filter and set members */
      const teamFilteredMemberArr = orgMembers.filter((member: any) =>
        value.members.includes(member.email)
      );
      setMemberOptions(teamFilteredMemberArr);
    }
  };

  const handleMemberChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: any,
    reason: any
  ) => {
    if (reason === "clear") {
      setSelectedMember(null);
    } else {
      setSelectedMember(value);
    }
  };

  return (
    <Box>
      <CssBaseline />
      <Grid container spacing={4} p={4}>
        <Grid item xs={4}>
          <CssBaseline />
          <Autocomplete
            disabled={collectionOptions.length === 0}
            disablePortal
            id="collectionSelect"
            options={collectionOptions}
            value={selectedCollection}
            sx={{ width: "100%", bgcolor: "white" }}
            slotProps={client.Autocomplete_SlotProps}
            getOptionLabel={(option: any) =>
              option.display_name ? option.display_name : option.id
            }
            isOptionEqualToValue={(option, value) =>
              option.display_name
                ? option.display_name === value.display_name
                : option.id === value.id || false
            }
            onChange={handleCollectionChange}
            renderInput={(collectionParams) => (
              <TextField
                {...collectionParams}
                label="Collections"
                color="secondary"
              />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <CssBaseline />
          <Autocomplete
            disabled={teamOptions.length === 0}
            disablePortal
            id="teamSelect"
            options={teamOptions}
            value={selectedTeam}
            sx={{
              width: "100%",
              bgcolor: "white",
            }}
            slotProps={client.Autocomplete_SlotProps}
            getOptionLabel={(option: any) =>
              option.display_name ? option.display_name : option.id
            }
            isOptionEqualToValue={(option, value) =>
              option.display_name
                ? option.display_name === value.display_name
                : option.id === value.id || false
            }
            onChange={handleTeamChange}
            renderInput={(teamParams) => (
              <TextField {...teamParams} label="Team" color="secondary" />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <CssBaseline />
          <Autocomplete
            disabled={memberOptions.length === 0}
            disablePortal
            id="memberSelect"
            options={memberOptions}
            value={selectedMember}
            sx={{ width: "100%", bgcolor: "white" }}
            slotProps={client.Autocomplete_SlotProps}
            getOptionLabel={(option: any) => option.email}
            isOptionEqualToValue={(option, value) =>
              option.email === value.email || false
            }
            onChange={handleMemberChange}
            renderInput={(memberParams) => (
              <TextField {...memberParams} label="Members" color="secondary" />
            )}
          />
        </Grid>

        {/* card */}
        <Grid item xs={5} md={4} lg={3}>
          <Paper
            elevation={4}
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              height: 300,
            }}
          >
            <TopSequences stepHistory={stepLog} />
          </Paper>
        </Grid>

        {/* User bar Chart */}
        <Grid item xs={7} md={8} lg={9}>
          <Paper
            elevation={4}
            sx={{
              p: 2,
              display: "flex",
              // alignItems: "center",
              flexDirection: "column",
              height: 300,
            }}
          >
            <UsageBarChart stepHistory={stepLog} />
          </Paper>
        </Grid>

        {/* Line Chart */}
        <Grid item xs={12}>
          <Paper
            elevation={4}
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              height: 260,
            }}
          >
            <UsageChart stepHistory={stepLog} />
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}