import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";

import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";

import Tooltip from "@mui/material/Tooltip";
import Skeleton from "@mui/material/Skeleton";

import UserContext from "../../../Contexts/userContext.tsx";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { FormGroup, FormControlLabel, Switch } from "@mui/material";
import { useEffect } from "react";

const defaultTeam = {
  collections: [],
  status: "active",
};

const rowHeaders = [
  "Name",
  "Status",
  "Collections",
  "# Members",
  "Created On",
  "Edit",
];

export default function TeamTable(props: {
  teams: any;
  setAddOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setEditOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setRemoveOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setTeam: React.Dispatch<React.SetStateAction<any>>;
  setItemType: React.Dispatch<React.SetStateAction<string>>;
}) {
  const { user } = React.useContext(UserContext);

  /* toggle controls */
  const [teamList, setTeamList] = React.useState<any>(
    props.teams.filter((member: any) => member.status === "active")
  );
  useEffect(() => {
    setTeamList(
      props.teams.filter((member: any) => member.status === "active")
    );
  }, [props.teams]);

  const [hideInactive, setHideInactive] = React.useState<boolean>(true);
  const showDisabled = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      let filteredMembers = props.teams.filter(
        (member: any) => member.status === "active"
      );
      setTeamList(filteredMembers);
    } else {
      setTeamList(props.teams);
    }
    setHideInactive(!hideInactive);
  };

  return (
    <Paper
      elevation={4}
      sx={{
        p: 2,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box p={2}>
        <Typography fontWeight="bold" color="primary.dark" variant="h5">
          Teams
        </Typography>
        <FormGroup sx={{ mt: -4 }}>
          <FormControlLabel
            control={
              <Switch
                color="secondary"
                checked={hideInactive}
                onChange={showDisabled}
                name="inactive toggle"
              />
            }
            label={hideInactive ? "Show Inactive" : "Hide Inactive"}
            labelPlacement="start"
          />
        </FormGroup>
      </Box>

      <Stack
        sx={{
          maxHeight: "300px",
          overflow: "scroll",
        }}
      >
        <Table stickyHeader size="small" style={{ width: "100%" }}>
          <TableHead>
            <TableRow>
              {rowHeaders.map((rowHeader: string) => (
                <TableCell
                  key={rowHeader}
                  align={rowHeader === "Edit" ? "center" : "left"}
                  sx={{ color: "secondary.dark" }}
                >
                  <b>{rowHeader}</b>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {teamList.length > 0 ? (
            <TableBody>
              {teamList.map((row: any) => (
                <TableRow key={row.id}>
                  <TableCell>{row.display_name || row.id}</TableCell>
                  <TableCell>
                    <Chip
                      label={row.status || "active"}
                      sx={{
                        fontWeight: "bold",
                        color:
                          row.status === "active"
                            ? "secondary.main"
                            : "divider",
                      }}
                      variant="outlined"
                    />
                  </TableCell>
                  <TableCell color="red">
                    {row.collections.length > 0 ? (
                      String(
                        row.collections.map(
                          (collection: string) => " " + collection
                        )
                      )
                    ) : (
                      <Chip
                        label={"no depratment selected"}
                        sx={{
                          fontWeight: "bold",
                          color: "divider",
                        }}
                        variant="outlined"
                      />
                    )}
                  </TableCell>
                  <TableCell>{row.members ? row.members.length : 0}</TableCell>
                  <TableCell>
                    {row.date_created
                      ? new Date(
                          row.date_created._seconds * 1000
                        ).toLocaleDateString("en-US")
                      : "unknown"}
                  </TableCell>
                  <TableCell align="right" width={120}>
                    <Tooltip title={"Edit Team"} placement="left">
                      <IconButton
                        aria-label="editTeam"
                        color="secondary"
                        onClick={() => {
                          props.setEditOpen(true);
                          props.setItemType("team");
                          props.setTeam({
                            id: row.id,
                            status: row.status || defaultTeam.status,
                            collections:
                              row.collections || defaultTeam.collections,
                            display_name: row.display_name || "",
                          });
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>

                    <Tooltip title={"Remove Team"} placement="right">
                      <IconButton
                        disabled={user.email === row.email}
                        aria-label="deleteTeam"
                        color="secondary"
                        onClick={() => {
                          props.setRemoveOpen(true);
                          props.setItemType("team");
                          props.setTeam({
                            id: row.id,
                            display_name: row.display_name || "",
                          });
                        }}
                      >
                        <RemoveCircleOutlineIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          ) : (
            <TableBody>
              <TableRow>
                {/* column for each header */}
                {rowHeaders.map((c, ic) => (
                  <TableCell key={ic}>
                    {/* n = 10 rows */}
                    {[...Array(10)].map((r, ir) => (
                      <Skeleton
                        key={"column:" + ic + "row:" + ir}
                        variant="text"
                        sx={{ width: "100%", fontSize: "1rem" }}
                      />
                    ))}
                  </TableCell>
                ))}
              </TableRow>
            </TableBody>
          )}
        </Table>
      </Stack>
      {/* <Link color="primary" href="#" onClick={preventDefault} sx={{ mt: 3 }}>
        See more activity
      </Link> */}
      <br />
      <Button
        variant="outlined"
        size="small"
        color="secondary"
        fullWidth
        onClick={() => {
          props.setAddOpen(true);
          props.setItemType("team");
          props.setTeam(defaultTeam);
        }}
      >
        Add Team
      </Button>
    </Paper>
  );
}
