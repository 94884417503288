import React, { useContext, useEffect } from "react";

import {
  Modal,
  Box,
  Alert,
  Typography,
  Stack,
  Button,
  LinearProgress,
} from "@mui/material";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";

import { ModalStyle2 } from "../../../Components/modalStyle.tsx";

import { GetOrgData, AddEditSequence } from "../../../firebase/getUserData.tsx";
import DashboardContext from "../../../Contexts/dashboardContext.tsx";
import UserContext from "../../../Contexts/userContext.tsx";
import { ModalList } from "../../../Config/styling.tsx";

export default function SequenceModal(props: {
  token: string;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  action: string;
  seqInfo: any;
}) {
  const { setOrgSeqGroups } = useContext(DashboardContext);
  const { logout } = useContext(UserContext);

  const [message, setMessage] = React.useState<any>("");

  useEffect(() => {
    // setMessage(props.info.message);
    (async () => {
      const sequenceTest = await validateSequence();

      if (sequenceTest.valid) {
        setMessage(
          <Box>
            <Alert severity={"info"}>Are you sure?</Alert>
            {props.action === "edit" && (
              <Typography variant="h6" p={2}>
                Please confirm your changes; existing values will be permanently
                overwritten.
              </Typography>
            )}
            {props.action === "add" && (
              <Typography variant="h6" p={2}>
                No errors detected - click 'Submit' to add this process.
              </Typography>
            )}
            <Stack direction="row" sx={{ bottom: 0 }}>
              <Button
                color="secondary"
                variant="contained"
                onClick={handleSubmit}
                fullWidth
                sx={{ m: 1 }}
              >
                SUBMIT
              </Button>
              <Button
                color="secondary"
                variant="outlined"
                fullWidth
                sx={{ m: 1 }}
                onClick={() => {
                  props.setOpen(false);
                }}
              >
                CANCEl
              </Button>
            </Stack>
          </Box>
        );
      } else {
        setMessage(
          <Box>
            <Alert severity="warning">Warning: errors detected</Alert>
            <Typography variant="h6" p={2}>
              You must correct the following errors to continue
            </Typography>
            <ModalList>
              {sequenceTest.errors?.map((error: any) => (
                <li key={`section-${error.category}`}>
                  <ul>
                    <ListSubheader sx={{ bgcolor: "primary.light" }}>
                      {error.category}
                    </ListSubheader>
                    {error.items.map((item) => (
                      <ListItem key={`item-${error.category}-${item}`}>
                        <ListItemText primary={item} />
                      </ListItem>
                    ))}
                  </ul>
                </li>
              ))}
            </ModalList>
            <Button
              variant="outlined"
              fullWidth
              onClick={() => {
                props.setOpen(false);
              }}
            >
              OKAY
            </Button>
          </Box>
        );
      }
    })();
  }, []);

  const handleSubmit = () => {
    setMessage(
      <Box sx={{ width: "100%" }}>
        <Alert severity={"info"}>Loading...</Alert>
        <br />
        <br />
        <LinearProgress color="secondary" />
        <br />
        <br />
      </Box>
    );

    AddEditSequence(
      logout,
      props.action,
      props.token,
      props.seqInfo.seqGroup,
      props.seqInfo.seqObj,
      props.seqInfo.seqId
    ).then((response: any) => {
      /* happy path */
      if (response.result === "success") {
        /* refresh orgSeqList */
        GetOrgData(props.token, ["sequenceList"], logout).then(
          (result: any) => {
            setOrgSeqGroups(result.sequenceGroups);
          }
        );

        setMessage(
          <Box sx={{ width: "100%" }}>
            <Alert severity={"success"}>{"SUCCESS"}</Alert>
            <Typography
              id="modal-modal-description"
              variant="h6"
              sx={{ mt: 2 }}
            >
              {response.message}
            </Typography>
            <br />
            <Button
              color="secondary"
              variant="outlined"
              fullWidth
              onClick={() => {
                props.setOpen(false);
              }}
            >
              OKAY
            </Button>
          </Box>
        );

        setTimeout(() => {
          props.setOpen(false);
        }, 7000);

        /* unhappy path */
      } else {
        setMessage(
          <Box sx={{ width: "100%" }}>
            <Alert severity={"error"}>{"ERROR"}</Alert>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              {/* {props.info.message} */}
              {response.message}
            </Typography>
            <Button
              variant="outlined"
              color="secondary"
              fullWidth
              sx={{ m: 1 }}
              onClick={() => {
                props.setOpen(false);
              }}
            >
              OKAY
            </Button>
          </Box>
        );
      }
    });
  };

  const validateSequence = () => {
    let tempErrors: any[] = [];

    /* sequence group errors */
    if (!props.seqInfo.seqGroup) {
      tempErrors.push({
        category: "Process Group",
        items: ["Select a process group"],
      });
    }

    /* sequence errors */
    let sequenceErrors: string[] = [];
    if (props.action === "add" && !props.seqInfo.seqObj.name) {
      sequenceErrors.push("Process name cannot be blank");
    }
    // } else if (props.action === "edit" && !props.seqInfo.seqId) {
    //   sequenceErrors.push("Select a process to edit");
    // }
    if (!props.seqInfo.seqObj.description) {
      sequenceErrors.push("Process Description cannot be blank");
    }
    if (!props.seqInfo.seqObj.initialUrl) {
      sequenceErrors.push("Initial URL cannot be blank");
    }
    if (sequenceErrors.length > 0) {
      tempErrors.push({
        category: "Process Info",
        items: sequenceErrors,
      });
    }
    if (
      !props.seqInfo.seqObj.stepList ||
      props.seqInfo.seqObj.stepList.length === 0
    ) {
      tempErrors.push({
        category: "No steps",
        items: ["Add at least one step to continue"],
      });
    } else {
      props.seqInfo.seqObj.stepList.forEach((step: any, index: number) => {
        let errorItems: any[] = [];
        /* need to handle both versions of the selector (old and new) */
        if (step.anchorElement) {
          if (!step.anchorElement.selector)
            errorItems.push("Selector cannot be blank");
        } else {
          if (!step.selector) errorItems.push("Selector cannot be blank");
        }
        if (!step.title) errorItems.push("Title cannot be blank");
        if (!step.description) errorItems.push("Description cannot be blank");

        if (errorItems.length > 0) {
          tempErrors.push({
            category: "Step " + (index + 1),
            items: errorItems,
          });
        }
      });
    }

    if (tempErrors.length > 0) {
      return { valid: false, errors: tempErrors };
    } else {
      return { valid: true };
    }
  };

  return (
    <Modal
      open={props.open}
      onClose={() => {
        props.setOpen(false);
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={ModalStyle2}>{message && <Box>{message}</Box>}</Box>
    </Modal>
  );
}
