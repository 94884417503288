export const ModalStyle = {
  position: "absolute" as "absolute",
  maxHeight: "80%",
  minHeight: "50%",
  maxWidth: "80%",
  minWidth: "350px",
  overflow: "scroll",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};


export const ModalStyle2 = {
  position: "absolute" as "absolute",
  maxHeight: "60%",
  width: "40%",
  overflow: "scroll",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};
