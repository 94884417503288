export const validateEmail = (email: string) => {
  const isValidEmail =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      email.toLowerCase()
    );
  if (!isValidEmail) {
    return false;
  }
  return true;
};

export const validatePassword = (password: string) => {
  const isValidPassword = password.length > 7;
  if (!isValidPassword) {
    return false;
  }
  return true;
};
