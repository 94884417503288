import React, { useContext } from "react";
import CssBaseline from "@mui/material/CssBaseline";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MemberTable from "./memberList.tsx";
import CollectionTable from "./collectionList.tsx";
import TeamTable from "./teamList.tsx";

import AddItemModal from "../modals/addItemModal.tsx";
import EditItemModal from "../modals/editItemModal.tsx";
import RemoveItemModal from "../modals/removeItemModal.tsx";
import DashboardContext from "../../../Contexts/dashboardContext.tsx";
import { Box } from "@mui/material";

export default function Users(props: { token: string }) {
  const [itemType, setItemType] = React.useState<string>("");

  const [addModalOpen, setAddModalOpen] = React.useState<boolean>(false);
  const [editModalOpen, setEditModalOpen] = React.useState<boolean>(false);
  const [removeModalOpen, setRemoveModalOpen] = React.useState<boolean>(false);
  const [itemToModify, setItemToModify] = React.useState<any>({});

  const { orgMembers, orgCollections, orgTeams } = useContext(DashboardContext);

  return (
    <Box>
      <CssBaseline />
      <Grid container spacing={4} p={4}>
        {" "}
        {/* Usage History */}
        <Grid item xs={12}>
          <MemberTable
            members={orgMembers}
            setAddOpen={setAddModalOpen}
            setEditOpen={setEditModalOpen}
            setRemoveOpen={setRemoveModalOpen}
            setUser={setItemToModify}
            setItemType={setItemType}
          />
        </Grid>
        <Grid item xs={12}>
          <CollectionTable
            collections={orgCollections}
            setAddOpen={setAddModalOpen}
            setEditOpen={setEditModalOpen}
            setRemoveOpen={setRemoveModalOpen}
            setCollection={setItemToModify}
            setItemType={setItemType}
          />
        </Grid>
        <Grid item xs={12}>
          <TeamTable
            teams={orgTeams}
            setAddOpen={setAddModalOpen}
            setEditOpen={setEditModalOpen}
            setRemoveOpen={setRemoveModalOpen}
            setTeam={setItemToModify}
            setItemType={setItemType}
          />
        </Grid>
      </Grid>
      {addModalOpen && (
        <AddItemModal
          open={addModalOpen}
          setOpen={setAddModalOpen}
          token={props.token}
          itemType={itemType}
          item={itemToModify}
        />
      )}
      {editModalOpen && (
        <EditItemModal
          open={editModalOpen}
          setOpen={setEditModalOpen}
          itemType={itemType}
          item={itemToModify}
          token={props.token}
        />
      )}
      {removeModalOpen && (
        <RemoveItemModal
          open={removeModalOpen}
          setOpen={setRemoveModalOpen}
          itemType={itemType}
          item={itemToModify}
          token={props.token}
        />
      )}
      <br />
      <br />
      <br />
    </Box>
  );
}
