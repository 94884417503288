import React, { useContext, useEffect, useReducer } from "react";

import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
  Navigate,
} from "react-router-dom";

import Dashboard from "./Pages/Dashboard/Dashboard.tsx";
import MenuAppBar from "./Components/header.tsx";
import HomePage from "./Pages/Home/Home.tsx";
import AboutPage from "./Pages/About/About.tsx";
import ContactPage from "./Pages/Contact/Contact.tsx";
import PrivacyPolicy from "./Pages/PrivacyPolicy.tsx";

import "./App.css";
import UserContext from "./Contexts/userContext.tsx";
import AppContext from "./Contexts/appContext.tsx";

import ThemeProvider from "@mui/material/styles/ThemeProvider";
import { DynamicTheme } from "./Config/styling.tsx";
import ErrorModal from "./Components/ErrorModal.tsx";

import { Snackbar } from "@mui/material";

const INITIAL_USER_STATE = {
  user: null,
};

const INITIAL_APP_STATE = {
  modalInfo: { open: false },
};

const userReducer = (state: any, action: any) => {
  switch (action.type) {
    case "login": {
      sessionStorage.setItem("userInfo", JSON.stringify(action.payload));

      return {
        ...state,
        user: action.payload,
        highlightColor: action.payload.orgInfo.highlightColor || "#FB8C37",
      };
    }
    case "logout":
      sessionStorage.clear();
      return {
        ...state,
        user: null,
        highlightColor: "#FB8C37",
        orgName: "",
      };
    case "updateUser":
      return {
        ...state,
        user: action.payload,
        highlightColor: action.payload.orgInfo.highlightColor || "#FB8C37",
      };

    // case "setHighlightColor":
    //   return {
    //     ...state,
    //     highlightColor: action.payload,
    //   };

    case "setOrgName":
      return {
        ...state,
        orgName: action.payload,
      };

    default:
      throw new Error(`Invalid action type: ${action.type}`);
  }
};

const appReducer = (state: any, action: any) => {
  switch (action.type) {
    case "setModalInfo":
      return {
        ...state,
        modalInfo: action.payload,
      };

    default:
      throw new Error(`Invalid action type: ${action.type}`);
  }
};

function App() {
  useEffect(() => {
    /* find out if the user info is in local storage */
    const userInfo = sessionStorage.getItem("userInfo");
    if (userInfo) {
      userDispatch({
        type: "updateUser",
        payload: JSON.parse(userInfo),
      });
    }

    /* set the Theme color from local storage */
    // const highlightColor = sessionStorage.getItem("highlightColor");
    // if (highlightColor) {
    //   userDispatch({
    //     type: "setHighlightColor",
    //     payload: JSON.parse(highlightColor),
    //   });
    // }
  }, []);

  const [userState, userDispatch] = useReducer(userReducer, INITIAL_USER_STATE);
  const [appState, appDispatch] = useReducer(appReducer, INITIAL_APP_STATE);

  const [snackbarOpen, setSnackbarOpen] = React.useState<boolean>(false);
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const currentUserState = {
    user: userState.user,
    // hasLoginError: userState.hasLoginError,
    login: (data: any) =>
      userDispatch({
        type: "login",
        payload: data,
      }),
    logout: (involuntary?: boolean) => {
      if (involuntary) setSnackbarOpen(true);
      userDispatch({ type: "logout" });
    },

    // highlightColor: userState.highlightColor || "",
    // setHighlightColor: (color: string) =>
    //   userDispatch({ type: "setHighlightColor", payload: color }),

    orgName: userState.orgName || "",
    setOrgName: (name: string) =>
      userDispatch({ type: "setOrgName", payload: name }),
  };

  const currentAppState = {
    modalInfo: userState.modalInfo || {},
    setModalInfo: (info: any) =>
      appDispatch({ type: "setModalInfo", payload: info }),
  };

  /* protected routes for logged in users only */
  const ProtectedRoute = () => {
    return userState.user ? <Outlet /> : <Navigate to="/" replace />;
  };

  return (
    <ThemeProvider
      theme={DynamicTheme(userState ? userState.highlightColor : "")}
    >
      <AppContext.Provider value={currentAppState}>
        <UserContext.Provider value={currentUserState}>
          <BrowserRouter>
            <MenuAppBar />
            <div className="App">
              <Routes>
                <Route element={<ProtectedRoute />}>
                  <Route path="dashboard/*" element={<Dashboard />} />
                </Route>

                <Route path="/" element={<HomePage />} />
                {/* <Route path="/about" element={<AboutPage />} /> */}
                <Route path="/contact" element={<ContactPage />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              </Routes>
            </div>
          </BrowserRouter>
        </UserContext.Provider>
        <ErrorModal info={appState.modalInfo} />
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={snackbarOpen}
          onClose={handleSnackbarClose}
          message="Logged out due to inactivity"
          key={"logout snackbar"}
          sx={{ zIndex: 5000 }}
        />
      </AppContext.Provider>
    </ThemeProvider>
  );
}

export default App;
