import { createContext } from "react";
const DashboardContext = createContext<any>({
  orgMembers: [],
  orgMembersActive: [],
  setOrgMembers: (members: any) => null,
  orgCollections: [],
  orgCollectionsActive: [],
  setOrgCollections: (members: any) => null,
  orgTeams: [],
  orgTeamsActive: [],
  setOrgTeams: (members: any) => null,
  stepHistory: {
    stepLog: [],
    errorLog: [],
  },
  setStepHistory: (history: any) => null,
  orgSeqGroups: [],
  setOrgSeqGroups: (sequenceGroups: any) => null,
  orgSequences: [],
});

export default DashboardContext;
