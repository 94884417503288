import { createContext } from "react";
const UserContext = createContext<any>({
  user: null,
  // hasLoginError: false,
  login: (data: any) => null,
  logout: (involuntary?: boolean) => null,
  highlightColor: "",
  // setHighlightColor: (color: string) => null,
  orgName: "",
  setOrgName: (name: string) => null,
});

export default UserContext;
