import React, { useEffect } from "react";

import { Typography, Card, CardContent, CardActionArea } from "@mui/material";

import { step } from "./types";

export default function StepCard(props: {
  index: number;
  step: step;
  selectedStepIndex: number;
  setSelectedStepIndex: React.Dispatch<React.SetStateAction<number>>;
}) {
  const [isSelected, setIsSelected] = React.useState<boolean>(
    props.selectedStepIndex === props.index
  );
  useEffect(() => {
    setIsSelected(props.selectedStepIndex === props.index);
  }, [props.selectedStepIndex]);

  const [step, setStep] = React.useState<step>(props.step);
  useEffect(() => {
    setStep(props.step);
  }, [props.step]);

  return (
    <Card
      elevation={4}
      sx={{
        m: 2,
        display: "flex",
        flexDirection: "column",
        width: "100%",
        bgcolor: "primary.light",
        border: isSelected ? 4 : 0,
        borderColor: "secondary.main",
      }}
    >
      <CardActionArea>
        <CardContent
          sx={{
            borderColor: "secondary",
            borderWidth: isSelected ? 4 : 0,
          }}
          onClick={() => props.setSelectedStepIndex(props.index)}
        >
          <Typography
            fontSize={"24px"}
            fontWeight={"bold"}
            color="primary.dark"
            fontStyle={step.title ? "normal" : "italic"}
          >
            {step.title || `Step ${props.index + 1} title`}
          </Typography>
          <Typography
            fontSize={"20px"}
            color="#999999"
            fontStyle={step.title ? "normal" : "italic"}
          >
            {step.subtitle || `Step ${props.index + 1} subtitle`}
          </Typography>
          <br />
          <Typography
            variant="body2"
            color="primary.dark"
            fontStyle={step.title ? "normal" : "italic"}
          >
            {step.description || `Step ${props.index + 1} description`}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
